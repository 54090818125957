import React from "react";

const Logo = () => {
  return (<svg width="179" height="56" viewBox="0 0 179 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M57.1382 55.9946H54.1084L32.7528 34.8415V55.9946H30.582V36.9061C30.582 35.7035 30.953 34.5975 31.587 33.6868L31.5926 33.6813L33.1173 32.1987C34.02 31.6047 35.1077 31.2594 36.2819 31.2594H54.8298V33.4096H34.3398L57.1373 55.9937L57.1382 55.9946Z" fill="currentColor" />
    <path d="M93.0833 55.9992H66.8441C63.6777 55.9992 61.1443 53.4899 61.1443 50.3525V48.2014C61.1443 45.064 63.6777 42.5547 66.8441 42.5547H90.9125V33.4133H62.3203V31.2622H87.3834C90.5499 31.2622 93.0833 33.7715 93.0833 36.908V55.9974V55.9992ZM90.9116 53.8481V44.7067H63.3151V53.8481H90.9116Z" fill="currentColor" />
    <path d="M131.085 50.3533C131.085 53.4898 128.552 56 125.385 56H100.322V31.2639H125.385C128.552 31.2639 131.085 33.7733 131.085 36.9097V50.3533ZM128.914 53.8489V33.415H102.493V53.8489H128.914Z" fill="currentColor" />
    <path d="M140.495 55.9993H138.324V31.2632H140.495V55.9993Z" fill="currentColor" />
    <path d="M178.498 50.3533C178.498 53.4898 175.964 56 172.797 56H153.434C150.267 56 147.734 53.4907 147.734 50.3533V36.9097C147.734 33.7733 150.267 31.2639 153.434 31.2639H172.797C175.963 31.2639 178.498 33.7733 178.498 36.9097V50.3533ZM176.326 53.8489V33.415H149.906V53.8489H176.326Z" fill="currentColor" />
    <path d="M63.3639 24.737H37.2149C33.4599 24.737 30.4292 21.735 30.4292 18.0157V6.72318C30.4292 3.00383 33.4599 0.00183105 37.2149 0.00183105H56.5782C60.3331 0.00183105 63.3639 3.00475 63.3639 6.72318V14.5201H34.7717V20.4357H63.3639V24.7379V24.737ZM59.0204 10.2178V4.30316H34.7717V10.2188H59.0204V10.2178Z" fill="currentColor" />
    <path d="M101.276 18.0157C101.276 21.735 98.2455 24.737 94.4905 24.737H68.3416V20.4348H96.9337V14.5192H73.9512C70.1963 14.5192 67.1655 11.5172 67.1655 7.79782V6.72226C67.1646 3.00383 70.1963 0.00183105 73.9512 0.00183105H100.1V4.30408H71.508V10.2197H94.4905C98.2455 10.2197 101.276 13.2226 101.276 16.941V18.0166V18.0157Z" fill="currentColor" />
    <path d="M28.5866 24.7315H22.5521L4.34344 6.70938V24.737H0V6.72411C0 5.49108 0.330964 4.33632 0.91666 3.34364C0.919449 3.33811 0.922238 3.33259 0.927816 3.32706C1.0589 3.10053 1.20207 2.88321 1.36197 2.67602C1.91327 1.95222 2.6096 1.34353 3.40633 0.891391C3.63503 0.76155 3.87489 0.642759 4.12032 0.537782C4.1259 0.535019 4.13427 0.532256 4.14077 0.529494C4.17517 0.515681 4.20957 0.500947 4.24397 0.489897C5.02583 0.172201 5.88856 0 6.7857 0H26.4223V4.30225H7.95058L28.5866 24.7315Z" fill="currentColor" />
  </svg>)
}

export default Logo;
import styled from "@emotion/styled";

const Alert = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
`;

export default Alert;
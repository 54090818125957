import React from "react";

const ScheduledBig = () => {
  return (<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.0014 17.0909C25.3189 17.0907 26.609 17.4672 27.7197 18.1759C28.8304 18.8846 29.7154 19.896 30.2704 21.0909H28.5453C28.4007 21.0909 28.2619 21.1484 28.1596 21.2507C28.0573 21.353 27.9999 21.4917 27.9999 21.6364C27.9999 21.781 28.0573 21.9198 28.1596 22.0221C28.2619 22.1244 28.4007 22.1818 28.5453 22.1818H31.2726C31.4655 22.1818 31.6504 22.1052 31.7868 21.9688C31.9232 21.8324 31.9998 21.6474 31.9998 21.4545V18.7273C31.9998 18.5826 31.9424 18.4439 31.8401 18.3416C31.7378 18.2393 31.599 18.1818 31.4544 18.1818C31.3097 18.1818 31.171 18.2393 31.0687 18.3416C30.9664 18.4439 30.9089 18.5826 30.9089 18.7273V19.9615C30.2039 18.7553 29.1951 17.755 27.9832 17.0599C26.7713 16.3649 25.3984 15.9995 24.0014 16C19.5825 16 16 19.5818 16 24C16 28.4182 19.5825 32 24.0014 32C28.3533 32 31.8937 28.5258 31.9998 24.2C32.0034 24.0553 31.9494 23.9152 31.8496 23.8104C31.7498 23.7055 31.6125 23.6447 31.4678 23.6411C31.3232 23.6375 31.183 23.6916 31.0782 23.7913C30.9734 23.8911 30.9125 24.0284 30.9089 24.1731C30.8638 25.9751 30.1162 27.6881 28.8257 28.9465C27.5351 30.205 25.8039 30.9093 24.0014 30.9091C20.1847 30.9091 17.0909 27.816 17.0909 24C17.0909 20.184 20.1847 17.0909 24.0014 17.0909Z" fill="currentColor" />
    <path d="M24.2037 20.5839C24.2037 20.429 24.1403 20.2805 24.0274 20.171C23.9146 20.0615 23.7615 20 23.6019 20C23.4422 20 23.2891 20.0615 23.1763 20.171C23.0634 20.2805 23 20.429 23 20.5839V24.8659C23 25.0761 23.1156 25.2692 23.3033 25.3727L26.112 26.9298C26.2502 27.0023 26.4122 27.0194 26.5634 26.9776C26.7145 26.9357 26.8427 26.8382 26.9207 26.7058C26.9987 26.5734 27.0202 26.4167 26.9806 26.2691C26.9411 26.1216 26.8436 25.9949 26.709 25.9161L24.2037 24.5272V20.5839Z" fill="currentColor" />
  </svg>)
}

export default ScheduledBig;

import React from "react";

const Search = () => {
  return (<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4934 20.2395C13.0804 20.2395 15.449 19.3307 17.2801 17.8298L23.9476 23.8393C24.1143 24 24.3326 24.0804 24.5521 24.0804C24.7716 24.0804 24.9899 24 25.1566 23.8393C25.4899 23.5178 25.4899 22.9961 25.1566 22.6746L18.489 16.6652C20.0454 14.8995 20.988 12.6143 20.988 10.1209C20.9891 4.5398 16.2812 0 10.4946 0C4.70794 0 0 4.53867 0 10.1198C0 15.7009 4.70677 20.2395 10.4934 20.2395ZM10.4934 1.64682C15.3375 1.64682 19.279 5.44754 19.279 10.1186C19.279 14.7897 15.3375 18.5904 10.4934 18.5904C5.6493 18.5904 1.70782 14.7897 1.70782 10.1186C1.70782 5.44754 5.6493 1.64682 10.4934 1.64682Z" fill="currentColor" />
  </svg>
  )
}

export default Search;
